<template>
  <div class="category">
    <div :class="category === 'video' ? 'category__name--active' : 'category__name'"
    @click="$emit('setCategory', 'video')">Video</div>
    <div :class="category === 'vocaburaly' ? 'category__name--active' : 'category__name'"
    @click="$emit('setCategory', 'vocaburaly')">Từ vựng</div>
  </div>
</template>

<script>
export default {
  props: {
    // 選択中のカテゴリー
    category: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.category {
  &__name {
    display: inline-block;
    margin-right: 18px;
    font-size: 20px;
    font-weight: bold;
    color: #A5A5A5;
    &--active {
      @extend .category__name;
      color: #393939;
      border-bottom: 3px solid #FF9534;
    }
  }
}
</style>
