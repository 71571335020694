<template>
  <div class="favorite">
    <category :category=category @setCategory="setCategory"></category>
    <video-list v-if="category === 'video' && !isGettingFavMovies" :movies=favMovies></video-list>
    <vocaburaly-list v-if="category === 'vocaburaly'"></vocaburaly-list>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Category from '@/components/favorite/category'
import VideoList from '@/components/video/list'
import VocaburalyList from '@/components/favorite/vocabularyList'

import CommonFunction from '@/lib/common'

// 画面に表示する最大動画数
const MAX_MOVIE_DISPLAY = 8

export default {
  extends: CommonFunction,
  components: { Category, VideoList, VocaburalyList },
  data () {
    return {
      // 選択中のカテゴリー
      category: 'video',
      // 表示中の動画数
      displayedMovieNum: 0,
      // 動画を取得中かどうか
      isGettingFavMovies: true,
      // お気に入り動画を追加中かどうか
      isAddingFavMovies: false
    }
  },
  mounted () {
    // お気に入りの動画ID一覧を取得して
    // 該当する動画を取得する
    this.addMovieFavs(this.uid).then(() => {
      // 表示件数分のみ取得
      const mids = this.favMIDs.slice(0, MAX_MOVIE_DISPLAY)
      this.displayedMovieNum = mids.length < MAX_MOVIE_DISPLAY ? mids.length + 1 : MAX_MOVIE_DISPLAY
      this.addFavMovies({ mids: mids, isFirst: true }).then(() => {
        this.isGettingFavMovies = false
      })
    })

    // スクロールイベントを追加
    window.addEventListener('scroll', this.onScroll)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {Array} お気に入り動画のIDの配列
     */
    favMIDs () {
      return this.getMovieAllFavs()()
    },
    /**
     * @return {Array} お気に入り動画の配列
     */
    favMovies () {
      return this.getFavMovies()()
    }
  },
  methods: {
    ...mapGetters('favMovie', ['getMovieAllFavs']),
    ...mapActions('favMovie', ['addMovieFavs']),
    ...mapGetters('movie', ['getFavMovies']),
    ...mapActions('movie', ['addFavMovies']),
    /**
     * カテゴリーを選択
     */
    setCategory (category) {
      this.category = category
    },
    /**
     * スクロールイベント
     */
    /**
     * scroll event
     * @return {void}
     */
    onScroll () {
      if (this.favMIDs.length > this.displayedMovieNum && !this.isGettingFavMovies && !this.isAddingFavMovies && this.isBottom()) {
        this.isAddingFavMovies = true
        const mids = this.favMIDs.slice(this.displayedMovieNum, this.displayedMovieNum + MAX_MOVIE_DISPLAY)
        this.displayedMovieNum = this.displayedMovieNum + mids.length
        this.addFavMovies({ mids: mids, isFirst: false }).then(() => {
          this.isAddingFavMovies = false
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.favorite {
  margin-bottom: $footer_height;
  padding: 0 $main_padding;
}
</style>
