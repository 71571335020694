<template>
  <v-container class="list" grid-list-lg>
    <v-layout row wrap v-if="!isGettingVocabularyFavs && existFavs">
      <template v-for="level in ['N5', 'N4', 'N3', 'N2']">
        <template v-for="genre in ['名詞', '動詞', '形容詞']">
          <v-flex v-if="favs[level][genre].length > 0" :key="level + genre" xs6
          @click="$router.push({ name: 'StudyVocabularyTest', query: { level: level, genre: genre } })">
            <div class="list__area">{{ level }}-{{ genre === '名詞' ? 1 : genre === '動詞' ? 2 : 3 }}</div>
            <h2 class="list__name--video">{{ level }}</h2>
            <h3 class="list__name--teacher">{{ genre === '名詞' ? 'Danh từ' : genre === '動詞' ? 'Động từ' : 'Tính từ' }}</h3>
          </v-flex>
        </template>
      </template>
    </v-layout>
    <div v-else-if="!isGettingVocabularyFavs" class="list__none">
      <img class="list__none__img" src="@/assets/img/settings-gray.svg">
      <p class="list__none__text">Bạn chưa thêm nội dung gì cả.</p>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      // お気に入り情報を取得中かどうか
      isGettingVocabularyFavs: true
    }
  },
  mounted () {
    // お気に入り情報の取得
    if (this.isAcquired) {
      this.isGettingVocabularyFavs = false
    } else {
      this.addVocabularyFavs(this.uid).then(() => {
        this.isGettingVocabularyFavs = false
      })
    }
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {Boolean} お気に入り情報を取得したかどうか
     */
    isAcquired () {
      return this.getIsAcquired()()
    },
    /**
     * @return {Boolean} お気に入り情報が存在するかどうか
     */
    existFavs () {
      const values = []
      Object.keys(this.favs).forEach(key => {
        Object.values(this.favs[key]).forEach(value => {
          if (value.length > 0) values.push(value)
        })
      })
      return values.length > 0
    },
    /**
     * @return {Object} お気に入り情報
     */
    favs () {
      return this.getVocabularyAllFavs()()
    }
  },
  methods: {
    ...mapGetters('favVocabulary', ['getIsAcquired', 'getVocabularyAllFavs']),
    ...mapActions('favVocabulary', ['addVocabularyFavs'])
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.list {
  padding: 20px 12px;
  &__area {
    height: 196px;
    background-color: #d2d2d2;
    border-radius: 10px;
    color: white;
    text-align: center;
    line-height: 196px;
    font-size: 24px;
    font-weight: bold;
  }
  &__name {
    margin: 0;
    font-size: 15px;
    &--video {
      @extend .list__name;
      margin-top: 5px;
      font-weight: bold;
      color: #707070;
    }
    &--teacher {
      @extend .list__name;
      font-weight: normal;
      color: #A5A5A5;
    }
  }
  &__none {
    width: 100%;
    margin-top: 80px;
    text-align: center;
    &__img {
      height: auto;
      width: 151.1px;
    }
    &__text {
      margin: 0;
      margin-left: 9px;
      font-size: 15px;
      font-weight: bold;
      color: #d4d4d4;
    }
  }
}
</style>
